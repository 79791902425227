// Import packages
import React, { useEffect, useState } from 'react'

// Define helper function
function createIdFromString(str) {
  return str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^a-z-]/gi, '')
}

// Define component
function Collapsable({ children, title }) {
  const [collapsed, setCollapsed] = useState(true)
  const [targeted, setTargeted] = useState(false)
  const id = createIdFromString(title)
  const toggleBody = (event) => {
    setCollapsed(!collapsed)
  }
    
  useEffect(() => {
    const hash = window.location.hash

    if (hash === `#${id}`) {
      setTargeted(true)
    }
  }, [id])

  return (
    <div id={id} className="collapsable" data-targeted={targeted}>
      <div className="collapsable__title" role="button" onClick={toggleBody}>
        {title} [<a className="collapsable__url" href={`#${id}`}>#</a>]
        {collapsed && !targeted && <i className="icon icon-e-add" />}
        {(!collapsed || targeted) && <i className="icon icon-e-delete" />}
      </div>
      <div className="collapsable__body" data-collapsed={collapsed}>
        {children}
      </div>
    </div>
  )
}

// Export component
export default Collapsable
