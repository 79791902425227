// Import packages
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'

// Import styles
import './styles/reset.scss'
import './styles/icons.scss'
import './styles/default.scss'

// Import views
import About from './views/About'
import Brand from './views/Brand'
import Careers from './views/Careers'
import Contact from './views/Contact'
import Default from './views/Default'
import Portfolio from './views/Portfolio'
import Privacy from './views/Privacy'

// Define app component
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Default />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/brand">
          <Brand />
        </Route>
        <Route exact path="/careers">
          <Careers />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/portfolio">
          <Portfolio />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

// Render app
ReactDOM.render(<App />, document.getElementById('root'))
