// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Privacy() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          Privacy policy
        </h2>
        <p>
          Updated 1 January 2020
        </p>
      </Hero>
      <Section>
        <p>
          Your privacy is important to us. We are committed to ensuring that your privacy is protected and that all personal information you submit, through <a href="https://www.lorenzinvest.com">lorenzinvest.com</a> or any other sites we own and operate, is handled securely.
        </p>
        <p>
          We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
        </p>
        <p>
          We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
        </p>
        <p>
          We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
        </p>
        <p>
          Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
        </p>
        <p>
          We use web analytics services that enables us to collect, analyze and report our web traffic data.
        </p>
        <p>
         You can choose not to accept cookies from our website, or you can disable cookies completely in your browser. Please consult your browser’s help menu as this procedure varies from browser to browser. If you choose to decline cookies, we may not be able to provide you with some of your desired services.
        </p>
        <p>
          Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us at <a href="mailto:privacy@lorenzinvest.com">privacy@lorenzinvest.com</a>.
        </p>
      </Section>
    </View>
  )
}

// Export component
export default Privacy
