// Import packages
import React from 'react'

// Define component
function Hero({ children, cover, imageSrc, videoSrc }) {
  return (
    <div className="hero" data-cover={cover}>
      {imageSrc && <div className="hero__background-image" style={{ backgroundImage: `url(/images/${imageSrc})` }} />}
      {videoSrc && <div className="hero__background-video">
        <video autoPlay muted loop playsInline>
          {videoSrc.map(v => <source src={`/videos/${v[0]}`} type={v[1]} />)}
        </video>
      </div>}
      <div className="hero__container">
        <div className="hero__text">
          {children}
        </div>
      </div>
    </div>
  )
}

// Export component
export default Hero
