// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Privacy() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          Contact
        </h2>
        <p>
          How to reach us
        </p>
      </Hero>
      <Section>
        <h3>
          Contact information
        </h3>
        <p>
          Please direct all inquiries to <a href="mailto:hello@lorenzinvest.com">hello@lorenzinvest.com</a>. For billing purposes and correspondence that can not be sent by email, please use the following mailing address:
        </p>
        <p>
          NO923976612<br />
          LRNZ AS<br />
          Markveien 2<br />
          NO-3117 Tønsberg, Norway
        </p>
      </Section>
    </View>
  )
}

// Export component
export default Privacy
