// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Default() {
  return (
    <View>
      <Hero cover imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h1>
          We are backing <br />clever people and <br />inspiring ideas<span className="color--lime">.</span>
        </h1>
      </Hero>
      <Section align="center">
        <h2>
          Lorenz is a private <br />investment firm in<br />the Nordic region<span className="color--lime">.</span>
        </h2>
      </Section>
      <Section imagePosition="left" imageSrc="two-people-on-mountain-cliff-1647962.jpg">
        <h2>
          What we do<span className="color--lime">.</span>
        </h2>
        <p>
          We are an investment firm focused on early-stage B2B companies. We are company builders with experience from the financial services, healthcare and technology sectors. We know what it takes to launch and establish a successful business. We are building Lorenz to be the partner we wish we had when we started our own journey.
        </p>
      </Section>
      <Section imagePosition="right" imageSrc="body-of-water-3011830.jpg">
        <h2>
          What we are looking for<span className="color--lime">.</span>
        </h2>
        <p>
          Coming up with an idea is hard. Executing on that idea is even harder. That is why we invest in the people behind an idea as much as we invest in the idea itself. We are always searching for skillful and passionate teams that can do what others have not been able to.
        </p>
      </Section>
      <Section imagePosition="left" imageSrc="low-angle-shot-of-high-rise-building-2096578.jpg">
        <h2>
          Our strategy<span className="color--lime">.</span>
        </h2>
        <p>
          We are builders as well as investors. We look for opportunities where we can take an active role in growing the business and optimize daily operations. We like to work closely with founders and their teams to optimize processes and business development strategies.
        </p>
      </Section>
    </View>
  )
}

// Export component
export default Default
