// Import packages
import React, { useEffect, useState } from 'react'

// Import components
import Consent from './Consent'
import Menu from './Menu'
import Footer from './Footer'

// Define component
function View({ children, title: initialTitle }) {
  const [title] = useState(initialTitle)
  
  useEffect(() => {
    const self = document

    self.title = title ? `${title} | Lorenz` : self.title
  }, [title])

  return (
    <>
      <Consent />
      <Menu />
      <div className="root">
        <main className="content">
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

// Export component
export default View
