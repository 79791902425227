// Import packages
import React from 'react'

// Import components
import Collapsable from '../components/Collapsable'
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Careers() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          Careers
        </h2>
        <p>
          Join our team
        </p>
      </Hero>
      <Section>
        <Collapsable title="Chief Financial Officer">
          <p>
            <strong>
              Lorenz is searching for a Chief Financial Officer (CFO) to join our executive management team.
            </strong>
          </p>
          <p>
            The position will report directly to our CEO and take lead on all financial activites in the company, including managing our investment portfolio.
          </p>
          <h4>
            Responsibilities
          </h4>
          <ul>
            <li>
              Internal and external financial resources
            </li>
            <li>
              Corporate accounting
            </li>
            <li>
              Regulatory and other financial reporting
            </li>
            <li>
              Internal policies and procedures
            </li>
            <li>
              Operating budgets, metrics and forecasts
            </li>
            <li>
              Payroll
            </li>
          </ul>
          <h4>
            Qualifications and skills
          </h4>
          <ul>
            <li>
              Bachelor's degree in business, accounting and/or finance
            </li>
            <li>
              Thorough knowledge of Norwegian accounting principles and procedures
            </li>
            <li>
              Experience with creating financial statements
            </li>
            <li>
              Experience with accounting software and administration
            </li>
            <li>
              Self-motivated and analytical personality
            </li>
          </ul>
          <h4>
            How to apply
          </h4>
          <p>
            Please <a href="/contact">write us</a> and tell us about yourself and how you would be a good fit for Lorenz. Use the subject line “<strong>CFO</strong>”.
          </p>
        </Collapsable>
        <Collapsable title="Controller">
          <p>
            <strong>
              Lorenz is searching for a hybrid Business–Financial Controller to handle our financial, accounting and human resources activities.
            </strong>
          </p>
          <p>
            The position will include all aspects of our financial management, including monitoring the financial performance of the company and our investment portfolio.
          </p>
          <h4>
            Responsibilities
          </h4>
          <ul>
            <li>
              Corporate accounting
            </li>
            <li>
              Regulatory and other financial reporting
            </li>
            <li>
              Internal policies and procedures
            </li>
            <li>
              Operating budgets, metrics and forecasts
            </li>
            <li>
              Payroll
            </li>
          </ul>
          <h4>
            Qualifications and skills
          </h4>
          <ul>
            <li>
              Bachelor's degree in business, accounting and/or finance
            </li>
            <li>
              Thorough knowledge of Norwegian accounting principles and procedures
            </li>
            <li>
              Experience with creating financial statements
            </li>
            <li>
              Experience with accounting software and administration
            </li>
            <li>
              Self-motivated and analytical personality
            </li>
          </ul>
          <h4>
            How to apply
          </h4>
          <p>
            Please <a href="/contact">write us</a> and tell us about yourself and how you would be a good fit for Lorenz. Use the subject line “<strong>Controller</strong>”.
          </p>
        </Collapsable>
        <Collapsable title="Open application">
          <p>
            We are always looking to add skillful people to our team. Please <a href="/contact">write us</a> and tell us about yourself and why you would be a good fit for Lorenz. Use the subject line “<strong>Open application</strong>”.
          </p>
        </Collapsable>
      </Section>
    </View>
  )
}

// Export component
export default Careers
