// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function About() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          About
        </h2>
        <p>
          This is our story
        </p>
      </Hero>
      <Section>
        <h3>
          Our background<span className="color--lime">.</span>
        </h3>
        <p>
          <strong>We began our journey at the intersection of healthcare, financial services and technology. For more than a decade we have been building various companies within this sphere, helping establish several market-leading ventures in Scandinavia.</strong>
        </p>
        <p>
          In Lorenz, we are creating the partner we never had. We want to help up-and-coming businesess learn from our success, failures and mishaps.
        </p>
        <h3>
          Our approach<span className="color--lime">.</span>
        </h3>
        <p>
          To help guide us in our endeavors, we have defined a set of core ideas to follow:
        </p>
        <h4>
          Work smart
        </h4>
        <p>
          Strive to do it right the first time.
        </p>
        <h4>
          Be curious
        </h4>
        <p>
          Ask questions and dare to fail.
        </p>
        <h4>
          Make things happen
        </h4>
        <p>
          Find answers and be optimistic.
        </p>
        <h4>
          Have fun
        </h4>
        <p>
          Smile a little!
        </p>
      </Section>
      <Section>
        <p>
          <strong>Are you interested in working toghether? We are always looking for new and exciting opportunities. Please <a href="/contact">write us</a> and tell us about you and your project.</strong>
        </p>
      </Section>
    </View>
  )
}

// Export component
export default About
