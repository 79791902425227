// Import packages
import React from 'react'

// Define component
function Section({ align, backgroundColor, textColor, children, className, contain, imagePosition, imageSrc }) {
  return (
    <section className={['section', className || ''].join(' ')} data-background-color={backgroundColor} data-text-color={textColor}>
      <div className="section__container" data-align={align} data-contain={contain || true} data-image-position={imagePosition}>
        {imagePosition === 'left' && <div className="section__image" style={{ backgroundImage: `url(/images/${imageSrc})` }} />}
        <div className="section__text">
          {children}
        </div>
        {imagePosition === 'right' && <div className="section__image" style={{ backgroundImage: `url(/images/${imageSrc})` }} />}
      </div>
    </section>
  )
}

// Export component
export default Section
