// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Privacy() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          Brand
        </h2>
        <p>
          Lorenz brand guidelines
        </p>
      </Hero>
      <Section>
        <h3>
          Logo
        </h3>
        <h4>
          Logotype
        </h4>
        <div className="logo__list">
          <div className="logo__item">
            <span className="color--avocado">lorenz</span><span className="color--lime">.</span>
          </div>
          <div className="logo__item">
            <span className="color--black">lorenz.</span>
          </div>
        </div>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="/images/lorenz-h-c.svg">
            Download SVG <i className="icon icon-arrow-right" />
          </a>
        </p>
        <h4>
          Symbol
        </h4>
        <div className="logo__list">
          <div className="logo__item">
            <span className="color--avocado">l</span><span className="color--lime">.</span>
          </div>
          <div className="logo__item">
            <span className="color--black">l.</span>
          </div>
        </div>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="/images/lorenz-s-c.svg">
            Download SVG <i className="icon icon-arrow-right" />
          </a>
        </p>
      </Section>
      <Section>
        <h3>
          Colors
        </h3>
        <div className="color__list">
          <div className="color__item" style={{ backgroundColor: 'rgb(10, 40, 60)', color: '#fff' }}>
            <div className="color__name">
              grape
            </div>
            <div className="color__hex">
              #0a283c
            </div>
            <div className="color__rgb">
              rgb(10, 40, 60)
            </div>
          </div>
          <div className="color__item" style={{ backgroundColor: 'rgb(15, 100, 100)', color: '#fff' }}>
            <div className="color__name">
              avocado
            </div>
            <div className="color__hex">
              #0f6464
            </div>
            <div className="color__rgb">
              rgb(15, 100, 100)
            </div>
          </div>
          <div className="color__item" style={{ backgroundColor: 'rgb(110, 220, 130)', color: '#0a283c' }}>
            <div className="color__name">
              lime
            </div>
            <div className="color__hex">
              #6edc82
            </div>
            <div className="color__rgb">
              rgb(110, 220, 130)
            </div>
          </div>
          <div className="color__item" style={{ backgroundColor: 'rgb(115, 200, 210)', color: '#0a283c' }}>
            <div className="color__name">
              agave
            </div>
            <div className="color__hex">
              #86d4e3
            </div>
            <div className="color__rgb">
              rgb(115, 200, 210)
            </div>
          </div>
          <div className="color__item" style={{ backgroundColor: 'rgb(230, 245, 235)', color: '#0a283c' }}>
            <div className="color__name">
              cucumber
            </div>
            <div className="color__hex">
              #e6f5eb
            </div>
            <div className="color__rgb">
              rgb(230, 245, 235)
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <h3>
          Typefaces
        </h3>
        <h4>
          Primary typefaces
        </h4>
        <p>
          Our primary typeface is <strong>IBM Plex Sans</strong>. This typeface is part of <a target="_blank" rel="noopener noreferrer" href="https://fonts.google.com/specimen/IBM+Plex+Sans">Google Fonts</a>. It is also available in Google Docs.
        </p>
        <div className="typeface__list">
          <div className="typeface__item" style={{ fontWeight: 300 }}>
            IBM Plex Sans Light
          </div>
          <div className="typeface__item" style={{ fontWeight: 400 }}>
            IBM Plex Sans Regular
          </div>
          <div className="typeface__item" style={{ fontWeight: 600 }}>
            IBM Plex Sans Semibold
          </div>
          <div className="typeface__item" style={{ fontWeight: 700 }}>
            IBM Plex Sans Bold
          </div>
        </div>
        <p>
          When <strong>IBM Plex Sans</strong> is not available, we use <strong>Verdana</strong>. This typeface is usuallty bundled with Windows, macOS and Linux.
        </p>
        <h4>
          Monospace typefaces
        </h4>
        <p>
          Code, spreadsheets and data visualization should be set in <strong>IBM Plex Mono</strong>. This typeface is part of <a target="_blank" rel="noopener noreferrer" href="https://fonts.google.com/">Google Fonts</a>. It is also available in Google Docs.
        </p>
        <div className="typeface__list">
          <div className="typeface__item" style={{ fontFamily: 'IBM Plex Mono', fontWeight: 400 }}>
            IBM Plex Mono Regular
          </div>
          <div className="typeface__item" style={{ fontFamily: 'IBM Plex Mono', fontWeight: 700 }}>
            IBM Plex Mono Bold
          </div>
        </div>
        <p>
          When <strong>IBM Plex Mono</strong> is not available, we use <strong>SF Mono</strong>. This typeface is bundled with macOS and is also available from <a target="_blank" rel="noopener noreferrer" href="https://developer.apple.com/fonts/">Apple</a>.
        </p>
      </Section>
    </View>
  )
}

// Export component
export default Privacy
