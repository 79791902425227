// Import packages
import React from 'react'

// Import components
import Hero from '../components/Hero'
import Section from '../components/Section'
import View from '../components/View'

// Define component
function Portfolio() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>
          Portfolio
        </h2>
        <p>
          Some of the companies we have supported
        </p>
      </Hero>
      <Section contain="false">
        <div className="portfolio__list">
          <div className="portfolio__item">
            <a className="portfolio__link" href="https://www.listfully.org/" target="_blank" rel="noopener noreferrer">
              Listfully
            </a>
          </div>
          <div className="portfolio__item">
            <a className="portfolio__link" href="https://www.convene.no/" target="_blank" rel="noopener noreferrer">
              Convene
            </a>
          </div>
          <div className="portfolio__item">
            <a className="portfolio__link" href="https://www.oivi.co/" target="_blank" rel="noopener noreferrer">
              Oivi
            </a>
          </div>
          <div className="portfolio__item">
            <a className="portfolio__link" href="https://www.patientsky.com/" target="_blank" rel="noopener noreferrer">
              PatientSky
            </a>
          </div>
        </div>
      </Section>
    </View>
  )
}

// Export component
export default Portfolio
