// Import packages
import React, { useEffect, useState } from 'react'

// Define component
function Consent({ maxAge, path }) {
  const [consent, setConsent] = useState(true)

  maxAge = maxAge || (60 * 60 * 24 * 365);
  path = path || '/'

  function acceptCookies() {
    document.cookie = `consent=${new Date().getTime()};path=${path};max-age=${maxAge};`;

    setConsent(true)
  }

  useEffect(() => {
    const cookies = document.cookie
    const consent = cookies.split(';').filter((x) => x.indexOf(`consent=`) >= 0)

    if (consent.length === 0) {
      setConsent(false)
    }
  }, [])

  return (
    <>
      {!consent && <div className="consent">
        <div className="consent__container">
          <div className="consent__text">
            We would like to use cookies to give you a better user experience. <a href="/privacy">Read more</a>.
          </div>
          <button className="consent__button" onClick={acceptCookies}>
            <i className="icon icon-check" /> Accept
          </button>
        </div>
      </div>}
    </>
  )
}

// Export component
export default Consent
